import SearchResults from './SearchResults.html'
import xhr from 'basic-xhr'
import html from 'html-template-tag'

const get = xhr()

const target = document.getElementById(`search-results-target`)
const loadingSpinner = document.getElementById(`loading-spinner`)

const searchQuery = new URL(location).searchParams.get(`query`)

const setMessage = html => {
	loadingSpinner.remove()
	target.innerHTML = html
}

if (searchQuery) {
	get(`https://search.kaysercommentary.com/?query=${ encodeURIComponent(searchQuery) }`).then(searchResults => {
		loadingSpinner.remove()

		new SearchResults({
			target,
			data: {
				searchResults,
				searchQuery,
			},
		})
	}).catch(res => {
		console.error(res)
		setMessage(html`<strong>Search server returned an error: ${ res.message || res }</strong>`)
	})

	document.getElementById(`search-input`).value = searchQuery
} else {
	setMessage(`<strong>You didn't provide a search query :-x</strong>`)
}
