<h1>Search results for "{searchQuery}":</h1>

<!--
<div>
	<strong>Categories</strong>
	{#each getChildCategories() as categoryChunk}

	{/each}
</div>
-->

<p>Can't find what you're looking for? Email us at <a href="mailto:info@biblicalblueprints.com">info@biblicalblueprints.com</a></p>

<ol class="list-without-bullets">
	{#each searchResults as searchResult}
		<li>
			<h2>
				<a href={'/' + searchResult.filename}>
					{searchResult.title}
				</a>
			</h2>
			<p class="post-subheader">
				{#if searchResult.passage}
					{searchResult.passage}
				{/if}
				{#if searchResult.passage && searchResult.date}
					·
				{/if}
				{#if searchResult.date}
					<em>{new Date(searchResult.date).toLocaleDateString()}</em>
				{/if}
			</p>
			<ul class="category-tags list-without-bullets">
				{#each searchResult.categories as category}
					<li class="category-tags-name">
						<a href="/category.md?category={encodeURIComponent(category)}">
							{category.split(/\s*\/\s*/).join(' › ')}
						</a>
					</li>
				{/each}
			</ul>
			{#if searchResult.description}
				<p>{searchResult.description}</p>
			{/if}
		</li>
	{/each}
</ol>

<style>
h1 {
	margin-bottom: 32px;
}
</style>

<script>
import makeTree from 'make-tree'
/*

*/
export default {
	data() {
		return {
			searchResults: [],
			searchQuery: ``,
		}
	},
	computed: {
		hierarchyMap: ({ searchResults }) => {
			const map = {}

			searchResults.forEach(result => {
				result.categories.forEach(category => {
					makeTree(category.split(`/`), map)
				})
			})

			return map
		},
		getChildCategories: ({ hierarchyMap }) => (...categories) => {
			const currentLevel = categories.reduce(
				(currentLevel, category) => currentLevel[category],
				hierarchyMap
			)

			return Object.key(currentLevel)
		},
	},
}
</script>
